import React from 'react';
import { Analytics } from '@vercel/analytics/react';


function App() {
  function handleDownload() {
    window.location.assign("https://kiffa-apks.s3.eu-central-1.amazonaws.com/karni.apk")
  }


  return (
    <div className="container mx-auto px-7">
      <div className='py-5 align-middle mb-14'>
        <p className='text-center font-logo text-3xl'>Karni</p>
      </div>
      <div className='flex flex-col items-center content-start'>
        <p className='font-app text-3xl mb-6 text-center'>L&apos;application uniquement disponible pour Android sera bientôt disponible pour iOS</p>
        <p className='font-app text-3xl mb-10  text-center'>التطبيق متاح لنظام اندرويد فقط وسيكون متاحًا لنظام آي فون قريبًا</p>
        <button type='submit' className='bg-black px-7 py-2 rounded-3xl font-app font-bold text-2xl text-white hover:bg-black' onClick={handleDownload}>Télécharger Karni</button>
      </div>
      <Analytics />
    </div>
  );
}

export default App;
